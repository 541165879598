
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as sairwCb9w7RaiJ6PQxZq8bXDHiKp3H_45uZYnTPZ9Om6wZL9EMeta } from "/home/runner/work/web-app/web-app/src/layers/auth/src/pages/sair.vue?macro=true";
import { default as indexUrCwAhUceQT9Ptj1peZ7arO_45XJeJ3xm2YaV4U_45HiIkgMeta } from "/home/runner/work/web-app/web-app/src/pages/index.vue?macro=true";
import { default as entrarPsTyIALA9ioCfMYYNh3oD48uhZQGrZHPCu7CGbFa3gAMeta } from "/home/runner/work/web-app/web-app/src/layers/auth/src/pages/entrar.vue?macro=true";
import { default as levitasGFHzEqGpCaIsDlf2mu6NsCYn868oPF49jemDu_45qEHPAMeta } from "/home/runner/work/web-app/web-app/src/pages/levitas.vue?macro=true";
import { default as index2DJxrLOmEqLNJEKJ4_45PBnjGj7KSCeLGxyIvyd1HYX1AMeta } from "/home/runner/work/web-app/web-app/src/layers/survey/src/pages/chat/index.vue?macro=true";
import { default as indexgcWpjy4Le_45SGwOvezH3F9Ajuoad4ii49yjviBBrfuWIMeta } from "/home/runner/work/web-app/web-app/src/pages/sobre/index.vue?macro=true";
import { default as autenticacaoZkSSkexVUTPCj6KzJ8kYNPMBukNH3m1VIpHIrMa3p2wMeta } from "/home/runner/work/web-app/web-app/src/layers/auth/src/pages/autenticacao.vue?macro=true";
import { default as acesso_45negadoq2fkDrnEX6p6waiYOZn68XuRl4K4BryyXW0L_j4jVqUMeta } from "/home/runner/work/web-app/web-app/src/layers/auth/src/pages/acesso-negado.vue?macro=true";
import { default as index053zT6l0ZdXnqNCC_45R7Z9XP6RQTmFMb8BZ0_jpoNiW4Meta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/index.vue?macro=true";
import { default as _2024rMlj6O4Or9vF_45QxltdLpS2n1Gu9Fjmjg9sYdyFsg2nAMeta } from "/home/runner/work/web-app/web-app/src/layers/survey/src/pages/pesquisa/2024.vue?macro=true";
import { default as index3qi1e3xhFOLTUIBcrzwiGFJT2BCjDiCPoFUBZXRtQA8Meta } from "/home/runner/work/web-app/web-app/src/pages/conectar/index.vue?macro=true";
import { default as turmasfWyOWgB_sedlUY3JLwzarlnWn7PE3xlE1P8Q6GG_rJcMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/conectar/turmas.vue?macro=true";
import { default as respostasAnNiNn8768h1WJJvoE3r7c0S4Shyw88JwEDD0sSlx2sMeta } from "/home/runner/work/web-app/web-app/src/layers/survey/src/pages/pesquisa/respostas.vue?macro=true";
import { default as politica_45privacidade4I35JLeOfU1ZKuAPm0RnySa8GZfmLKVd2RjcpKvAR3EMeta } from "/home/runner/work/web-app/web-app/src/pages/politica-privacidade.vue?macro=true";
import { default as indexhBnpeddpwCrqaI8ldfqWQAbyUEMxwDtpRgPufZw1_45AcMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/escalas/index.vue?macro=true";
import { default as indexOyU8a4cgtaM_Xfapj8xy392VIAliiSPg_45EsDFIcHho4Meta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/conectar/index.vue?macro=true";
import { default as indexMPy3PtIu6KnE5YcSIv_45k_45O7259mlyxqnjzZ0kFbPmikMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/conectar/checkin/index.vue?macro=true";
import { default as indexGaBmVRXN7KYSyLvMJihesOkbvJ1FHPloITAPojnF3WsMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/ministerios/index.vue?macro=true";
import { default as indexfKG_WQ5Fq2Hz2jKDp22qVUkcIO_xKRDdGYUWprAIh3YMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/voluntarios/index.vue?macro=true";
import { default as _91turma_93gKsHSvGEy2oPY3EQOBE3QP033y99W_pvfRGkUbypwbYMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/conectar/inscricao/[turma].vue?macro=true";
import { default as _91turma_93jrRporNf6qdIbbg0cWTwtI9PUodQdtsslAwJPaPxwpMMeta } from "/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/voluntariar/[turma].vue?macro=true";
import { default as component_45stubrJ8oI50SggVGuHoWgoNSZ28lSs3Xr8MSehuGpt1Ir_45cMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.17.25_eslint@9.23.0_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubrJ8oI50SggVGuHoWgoNSZ28lSs3Xr8MSehuGpt1Ir_45c } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.17.25_eslint@9.23.0_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "sair",
    path: "/sair",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/pages/sair.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/index.vue")
  },
  {
    name: "entrar",
    path: "/entrar",
    meta: entrarPsTyIALA9ioCfMYYNh3oD48uhZQGrZHPCu7CGbFa3gAMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/pages/entrar.vue")
  },
  {
    name: "levitas",
    path: "/levitas",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas.vue")
  },
  {
    name: "chat",
    path: "/chat",
    meta: index2DJxrLOmEqLNJEKJ4_45PBnjGj7KSCeLGxyIvyd1HYX1AMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/survey/src/pages/chat/index.vue")
  },
  {
    name: "sobre",
    path: "/sobre",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/sobre/index.vue")
  },
  {
    name: "autenticacao",
    path: "/autenticacao",
    meta: autenticacaoZkSSkexVUTPCj6KzJ8kYNPMBukNH3m1VIpHIrMa3p2wMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/pages/autenticacao.vue")
  },
  {
    name: "acesso-negado",
    path: "/acesso-negado",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/auth/src/pages/acesso-negado.vue")
  },
  {
    name: "central",
    path: "/central",
    meta: index053zT6l0ZdXnqNCC_45R7Z9XP6RQTmFMb8BZ0_jpoNiW4Meta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/index.vue")
  },
  {
    name: "pesquisa-2024",
    path: "/pesquisa/2024",
    meta: _2024rMlj6O4Or9vF_45QxltdLpS2n1Gu9Fjmjg9sYdyFsg2nAMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/survey/src/pages/pesquisa/2024.vue")
  },
  {
    name: "conectar",
    path: "/conectar",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/conectar/index.vue")
  },
  {
    name: "conectar-turmas",
    path: "/conectar/turmas",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/conectar/turmas.vue")
  },
  {
    name: "pesquisa-respostas",
    path: "/pesquisa/respostas",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/survey/src/pages/pesquisa/respostas.vue")
  },
  {
    name: "politica-privacidade",
    path: "/politica-privacidade",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/politica-privacidade.vue")
  },
  {
    name: "central-escalas",
    path: "/central/escalas",
    meta: indexhBnpeddpwCrqaI8ldfqWQAbyUEMxwDtpRgPufZw1_45AcMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/escalas/index.vue")
  },
  {
    name: "central-conectar",
    path: "/central/conectar",
    meta: indexOyU8a4cgtaM_Xfapj8xy392VIAliiSPg_45EsDFIcHho4Meta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/conectar/index.vue")
  },
  {
    name: "conectar-checkin",
    path: "/conectar/checkin",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/conectar/checkin/index.vue")
  },
  {
    name: "central-ministerios",
    path: "/central/ministerios",
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/ministerios/index.vue")
  },
  {
    name: "central-voluntarios",
    path: "/central/voluntarios",
    meta: indexfKG_WQ5Fq2Hz2jKDp22qVUkcIO_xKRDdGYUWprAIh3YMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/voluntarios/index.vue")
  },
  {
    name: "conectar-inscricao-turma",
    path: "/conectar/inscricao/:turma()",
    meta: _91turma_93gKsHSvGEy2oPY3EQOBE3QP033y99W_pvfRGkUbypwbYMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/conectar/inscricao/[turma].vue")
  },
  {
    name: "central-voluntariar-turma",
    path: "/central/voluntariar/:turma()",
    meta: _91turma_93jrRporNf6qdIbbg0cWTwtI9PUodQdtsslAwJPaPxwpMMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/src/layers/central/src/pages/central/voluntariar/[turma].vue")
  },
  {
    name: component_45stubrJ8oI50SggVGuHoWgoNSZ28lSs3Xr8MSehuGpt1Ir_45cMeta?.name,
    path: "/15anos",
    component: component_45stubrJ8oI50SggVGuHoWgoNSZ28lSs3Xr8MSehuGpt1Ir_45c
  }
]