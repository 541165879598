import routerOptions0 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.17.25_eslint@9.23.0_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.7.1_typescript@5.8.2_vite@6.2.2_vue@3.5.13/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}