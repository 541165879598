import payload_plugin_YCjKXCex8TyfoDfdF4jXX8uiFRVRlXlL53_dy811G6Q from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase-functions@4.9.0_f_q7zbigvto4evxf34uygx6q54cu/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_khe7ZSU0OziI1JpyhT2CTy5JIIEFtmFUM9go4btx_aE from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.17.25_eslint@9.23.0_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1rQuUZPD_aWG4akheEEFoFVv48VV2RBUXUMufVkgPjM from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.17.25_eslint@9.23.0_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_PnrRHgxqHkTpcDRnk6VNqzlN_IJ6wuFAetZycfzooPY from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.17.25_eslint@9.23.0_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_zHeCPGaLuqu_opSVua_2SYDiswvqg_at19AaRQhe4BI from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-site-config@2.2.21_vite@6.2.2_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_cbckEdjgiURwESbz4k7bhT6Nym3xKjy6FqZ77wMDzJU from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.17.25_eslint@9.23.0_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3AIMxo6zscirU7o3_FMAlUzhPKrtDjR9YPOl3p6Z664 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.17.25_eslint@9.23.0_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_m62BJFHWcOm3Q_KJyD70TfwuGFINprSmqS5fi1by6OI from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.17.25_eslint@9.23.0_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8YunDE_PJzdlIhBv4tG2dLqI1NV5sEaZ3DXNBhxamWA from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.17.25_eslint@9.23.0_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_DXDPEuw6kESCr7Vgg_qwHLOQB29Fb41e7HfQ9Yoviv8 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.8.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_ESO4hmPgIdeNCj15XXMJITtZdq4O3YD7rNv76gyXmUU from "/home/runner/work/web-app/web-app/.nuxt/runtime/plugin.ts";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/runner/work/web-app/web-app/.nuxt/components.plugin.mjs";
import prefetch_client_8Iveqdbo6evAVma2Nwoa2wBCoEFohtUhikZmlqYST0Y from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.16.1_@types+node@20.17.25_eslint@9.23.0_typescript@5.8.2_vite@6.2.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_GjYg_fruZImJrlVQPl1Q0ZjFKL9U2rcJVgr21YrfDP4 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui@2.21.1_typescript@5.8.2_vite@6.2.2_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_gxKAvr8wF_TxCSpfhUdajfrKEYxugr7G1JDbCxJiy0M from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui@2.21.1_typescript@5.8.2_vite@6.2.2_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_WgQDIx7Ls4X2EE0v2qc_RlMIAhkV0YWXNqwTsYdzV6M from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui@2.21.1_typescript@5.8.2_vite@6.2.2_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_edbSxve_ISwKTvUFqtVbK44_3Z6bNsEgFUl_SAaH5iQ from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_1fcQ0VLwymyW7T_FZFDgsN96eYpRb7HCZhNixMWbbs4 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+icon@1.11.0_vite@6.2.2_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_GscnjqsyUSZrbi_0qK5a0wQCuvtVf56ALvGMd_JRBJQ from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase-functions@4.9.0_f_q7zbigvto4evxf34uygx6q54cu/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_5mchQbrFji2Q4C_SfSquLdjPW_bEWAXa5RNJAOVNHR0 from "/home/runner/work/web-app/web-app/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_3yC4ojquQ_IfYhLPId43C0hGmDvAqA3w4G_VdIrFV5M from "/home/runner/work/web-app/web-app/.nuxt/vuefire-plugin.mjs";
import plugin_client__p8AFbNq2A94YJLuCTS7pLFozrBtYuar1yppF4pZG1I from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.5_@firebase+app-types@0.9.3_firebase-admin@12.7.0_firebase-functions@4.9.0_f_q7zbigvto4evxf34uygx6q54cu/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import scrollbars_client_CL12IbFElBjAn_l8Nqje_7JynybCfNkybjbJVyRYAcw from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.7.1_typescript@5.8.2_vite@6.2.2_vue@3.5.13/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_qRPMEqVDUGOsPMuOez51SxA_p963U0_xeUN65FedO_c from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.7.1_typescript@5.8.2_vite@6.2.2_vue@3.5.13/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_piCht7Xeval1cIlj8g8qJ5ddyHnSD74oSTxL7i7ZW_o from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+ui-pro@1.7.1_typescript@5.8.2_vite@6.2.2_vue@3.5.13/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import maska_76d2Raui1GZTxxYbhTB385_78lJO08PyscOmXfXMIYs from "/home/runner/work/web-app/web-app/src/layers/components/src/plugins/maska.ts";
import api_MF7sEHXPzqGaBTq3BoZ7SjE5SFzWRw2sAsY4QppP2ig from "/home/runner/work/web-app/web-app/src/layers/commons/src/plugins/api.ts";
import remoteConfig_client_IBg_98BT_rNCq_W0LschUNLnOghCh9WcItH9WwMVWgs from "/home/runner/work/web-app/web-app/src/layers/commons/src/plugins/remoteConfig.client.ts";
export default [
  payload_plugin_YCjKXCex8TyfoDfdF4jXX8uiFRVRlXlL53_dy811G6Q,
  revive_payload_client_khe7ZSU0OziI1JpyhT2CTy5JIIEFtmFUM9go4btx_aE,
  unhead_1rQuUZPD_aWG4akheEEFoFVv48VV2RBUXUMufVkgPjM,
  router_PnrRHgxqHkTpcDRnk6VNqzlN_IJ6wuFAetZycfzooPY,
  _0_siteConfig_zHeCPGaLuqu_opSVua_2SYDiswvqg_at19AaRQhe4BI,
  payload_client_cbckEdjgiURwESbz4k7bhT6Nym3xKjy6FqZ77wMDzJU,
  navigation_repaint_client_3AIMxo6zscirU7o3_FMAlUzhPKrtDjR9YPOl3p6Z664,
  check_outdated_build_client_m62BJFHWcOm3Q_KJyD70TfwuGFINprSmqS5fi1by6OI,
  chunk_reload_client_8YunDE_PJzdlIhBv4tG2dLqI1NV5sEaZ3DXNBhxamWA,
  plugin_vue3_DXDPEuw6kESCr7Vgg_qwHLOQB29Fb41e7HfQ9Yoviv8,
  plugin_ESO4hmPgIdeNCj15XXMJITtZdq4O3YD7rNv76gyXmUU,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_8Iveqdbo6evAVma2Nwoa2wBCoEFohtUhikZmlqYST0Y,
  slideovers_GjYg_fruZImJrlVQPl1Q0ZjFKL9U2rcJVgr21YrfDP4,
  modals_gxKAvr8wF_TxCSpfhUdajfrKEYxugr7G1JDbCxJiy0M,
  colors_WgQDIx7Ls4X2EE0v2qc_RlMIAhkV0YWXNqwTsYdzV6M,
  plugin_client_edbSxve_ISwKTvUFqtVbK44_3Z6bNsEgFUl_SAaH5iQ,
  plugin_1fcQ0VLwymyW7T_FZFDgsN96eYpRb7HCZhNixMWbbs4,
  plugin_client_GscnjqsyUSZrbi_0qK5a0wQCuvtVf56ALvGMd_JRBJQ,
  vuefire_auth_plugin_client_5mchQbrFji2Q4C_SfSquLdjPW_bEWAXa5RNJAOVNHR0,
  vuefire_plugin_3yC4ojquQ_IfYhLPId43C0hGmDvAqA3w4G_VdIrFV5M,
  plugin_client__p8AFbNq2A94YJLuCTS7pLFozrBtYuar1yppF4pZG1I,
  scrollbars_client_CL12IbFElBjAn_l8Nqje_7JynybCfNkybjbJVyRYAcw,
  presets_qRPMEqVDUGOsPMuOez51SxA_p963U0_xeUN65FedO_c,
  variables_piCht7Xeval1cIlj8g8qJ5ddyHnSD74oSTxL7i7ZW_o,
  maska_76d2Raui1GZTxxYbhTB385_78lJO08PyscOmXfXMIYs,
  api_MF7sEHXPzqGaBTq3BoZ7SjE5SFzWRw2sAsY4QppP2ig,
  remoteConfig_client_IBg_98BT_rNCq_W0LschUNLnOghCh9WcItH9WwMVWgs
]